<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-data-table
      :headers="headers"
      :items="inbounds"
      :loading="isLoadingInbounds"
      :server-items-length="inboundsMeta.totalItems"
      :options.sync="options"
      :items-per-page="30"
      :footer-props="{ 'items-per-page-options': [10, 30, 50] }"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="$admin.can('inbound-view')"
          class="mr-2"
          color="#C6C6C6"
          @click="showItem(item)"
        >
          $iconViewItem
        </v-icon>
        <v-tooltip bottom v-if="canPrint(item)">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :disabled="is_loading_invoice && index == item.id"
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              color="#C6C6C6"
              @click="getInvoice(item)"
            >
              $iconDownloadSlip
            </v-icon>
          </template>
          <span>{{ $t("receipt_slip") }}</span>
        </v-tooltip>
        <v-tooltip bottom v-if="canReopen(item)">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              color="#C6C6C6"
              @click="reopenInbound(item)"
            >
              $iconRestoreItem
            </v-icon>
          </template>
          <span>{{ $t("reopen_inbound") }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.inboundStatus="{ item }">
        <span
          :class="getColor(item.inboundStatus.name)"
          class="font-weight-medium text-capitalize"
        >
          {{ item.inboundStatus.name }}
        </span>
      </template>
      <template v-slot:top>
        <v-row class="my-2">
          <v-col cols="12" sm="2" md="2">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="filters.search"
              :label="$t('search')"
              class=""
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" md="2" class="px-0">
            <v-autocomplete
              outlined
              dense
              hide-details
              clearable
              v-model="filters.from_provider_id"
              :items="providers"
              item-text="company_name"
              item-value="id"
              :label="$t('provider')"
              :search-input.sync="search_from_provider"
              :loading="is_loading_providers"
              no-filter
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2" md="2">
            <v-autocomplete
              outlined
              dense
              hide-details
              clearable
              v-model="filters.to_hub_id"
              :items="hubs"
              item-text="name"
              item-value="id"
              :label="$t('hub')"
              :search-input.sync="search_to_hub"
              :loading="is_loading_hubs"
              no-filter
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" class="px-0">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  clearable
                  v-model="filters.created_date_range"
                  :label="$t('created_date_range')"
                  append-icon="mdi-calendar"
                  readonly
                  chips
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.created_date_range"
                range
                no-title
                color="primary"
                class="my-0 py-4"
                header-color="primary"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="3">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  clearable
                  v-model="filters.reception_date_range"
                  :label="$t('reception_date_range')"
                  append-icon="mdi-calendar"
                  readonly
                  chips
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.reception_date_range"
                range
                no-title
                color="primary"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </template>
      <template v-slot:no-data>
        {{ $t("no_data_available") }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";
import { inboundPermissions } from "@/mixins/permissions";

export default {
  mixins: [inboundPermissions],
  components: {},

  props: {
    isActive: {
      types: Boolean,
    },
  },

  computed: {
    ...mapGetters({
      isLoadingInbounds: "inboundProducts/isLoadingInbounds",
      inbounds: "inboundProducts/listInbounds",
      inboundsMeta: "inboundProducts/meta",
      hubs: "hubs/activeHubs",
      providers: "providers/activeProviders",
    }),

    filters: {
      get() {
        return this.$store.state.inboundProducts.filters;
      },
      set(value) {
        this.$store.commit("inboundProducts/SET_FILTERS", value);
      },
    },
  },

  watch: {
    options: {
      handler: debounce(async function (params) {
        if (this.isActive) {
          try {
            await this.$store.dispatch("inboundProducts/list", {
              ...params,
              ...this.filters,
            });
          } catch (error) {
            this.$store.dispatch(
              "alerts/error",
              error?.response?.data?.message
            );
          }
        }
      }, 1000),
      deep: true,
    },
    filters: {
      handler: debounce(async function (params) {
        if (this.isActive) {
          try {
            await this.$store.dispatch("inboundProducts/list", {
              ...params,
              ...this.options,
            });
          } catch (error) {
            this.$store.dispatch(
              "alerts/error",
              error?.response?.data?.message
            );
          }
        }
      }, 1000),
      deep: true,
    },

    search_from_provider: debounce(async function (search) {
      if (this.is_loading_providers) return;
      this.is_loading_providers = true;

      await this.$store
        .dispatch("providers/list", {
          itemsPerPage: 30,
          search,
        })
        .then(() => {
          this.is_loading_providers = false;
        });
    }, 1000),

    search_to_hub: debounce(function (search) {
      if (this.is_loading_hubs) return;
      this.is_loading_hubs = true;

      this.$store
        .dispatch("hubs/list", {
          itemsPerPage: 30,
          for: "inbound",
          search,
        })
        .then(() => {
          this.is_loading_hubs = false;
        });
    }, 1000),
  },

  data() {
    return {
      options: {},
      search_to_hub: "",
      search_from_provider: "",
      is_loading_providers: false,
      is_loading_inbounds: false,
      is_loading_hubs: false,
      index: 0,
      is_loading: false,
      is_loading_invoice: false,
      is_loading_dn: false,

      headers: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "tracking_number",
        },
        {
          text: this.$t("order_id"),
          align: "start",
          sortable: false,
          value: "supplyOrder.tracking_number",
        },

        {
          text: this.$t("from_provider"),
          align: "start",
          sortable: false,
          value: "fromProvider.company_name",
        },
        {
          text: this.$t("to_hub"),
          align: "start",
          sortable: false,
          value: "toHub.name",
        },

        {
          text: this.$t("delivery_date"),
          value: "expected_delivery_date",
          align: "end",
          sortable: true,
        },
        {
          text: this.$t("reception_date"),
          value: "received_at",
          align: "end",
          sortable: true,
        },
        {
          text: this.$t("status"),
          value: "inboundStatus",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("actions"),
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
    };
  },

  methods: {
    getColor(status) {
      if (status === "closed") return "green--text";
      else return "grey--text";
    },

    showItem(item) {
      this.$router.push({
        name: "inbound.products.show",
        params: { id: item.id },
      });
    },

    async getInvoice(item) {
      this.index = item.id;
      this.is_loading_invoice = true;
      await this.$store
        .dispatch("inboundProducts/getInvoice", item)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "order_" + item.tracking_number + ".pdf"
          );
          document.body.appendChild(link);
          // window.open(link);
          link.click();
          this.is_loading_invoice = false;
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.statusText);
          this.is_loading_invoice = false;
        });
    },

    async reopenInbound(item) {
      this.index = item.id;
      this.is_loading = true;
      try {
        const resultSwal = await this.$swal({
          icon: "question",
          text: this.$t("reopen_inbound_confirmation"),
          showConfirmButton: true,
          showCancelButton: true,
        });

        if (resultSwal.isConfirmed) {
          await this.$store.dispatch("inboundProducts/reopen", item);
          await this.$store.dispatch("inboundProducts/list", this.filters);
          this.$store.dispatch(
            "alerts/success",
            this.$t("updated_successfully")
          );
        }

        this.is_loading = false;
      } catch (error) {
        this.is_loading = false;
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>
